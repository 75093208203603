.spring_break_april {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;

  background: {
    color: #ff3a05;
    position: center center;
    repeat: no-repeat;
  }

  white-space: normal;

  @at-root .no-webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/spring_break_april/bg-sm.png');
  }

  @at-root .webp-lossy &.slider__slide--woken {
    background-image: url('pages/home_page/promotions_slider/spring_break_april/bg-sm.webp');
  }

  .content_container {
    max-width: rem-calc(320);
    padding: rem-calc(40 0 0);
    align-items: flex-start;
    align-content: flex-start;
  }

  .description {
    padding: 0;
    text-shadow: none;
  }

  .description1,
  .image-container2,
  .discount-container {
    display: none;
    visibility: hidden;
  }

  .text-with-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem-calc(7);
    color: $black;
    line-height: 1;

    font: {
      size: rem-calc(18);
      weight: 500;
    }

    &__text {
      margin-top: rem-calc(2);
    }

    &__code {
      margin-left: rem-calc(3);
    }
  }

  .text-with-discount {
    line-height: 1.1;

    font: {
      size: rem-calc(16);
      weight: 500;
    }

    b {
      color: $white;

      font: {
        weight: 800;
      }
    }
  }

  .note_container {
    @include horizontal-center;
    bottom: rem-calc(2);
    width: 100%;
    max-width: rem-calc(1785);
    text-align: center;
    line-height: 1;
    color: $white;

    font: {
      size: rem-calc(14);
    }
  }
}

@include breakpoint(medium up) {
  .spring_break_april {
    .description {
      text-align: center;
    }
  }
}

@include breakpoint(large up) {
  .spring_break_april {
    .content_container {
      max-width: rem-calc(1785);
      justify-content: center;
      align-content: flex-end;
      align-items: flex-end;
    }

    .description1,
    .image-container2,
    .discount-container {
      display: block;
      visibility: visible;
    }

    .image-container1 {
      display: none;
      visibility: hidden;
    }

    .description {
      flex: 0 0 auto;
      width: rem-calc(345);
    }

    .description1 {
      margin-bottom: rem-calc(85);
    }

    .image-wrap {
      margin-bottom: rem-calc(52);
    }

    .description2 {
      position: relative;
      margin-bottom: rem-calc(85);
      text-align: left;
    }

    .title-container {
      display: block;
      width: rem-calc(365);

      img {
        width: 100%;
      }
    }

    .text-with-code,
    .text-with-discount {
      position: absolute;
    }

    .text-with-code {
      bottom: rem-calc(-25);
      left: 0;

      font: {
        size: rem-calc(20);
      }

      &__code-container {
        display: block;
        width: rem-calc(97);

        img {
          width: 100%;
        }
      }
    }

    .text-with-discount {
      bottom: rem-calc(-52);
      left: rem-calc(205);
      line-height: 1.3;

      font: {
        size: rem-calc(18);
      }

      b {
        display: none;
        visibility: hidden;
      }

      span {
        display: block;
        color: $white;

        font: {
          weight: 700;
        }
      }
    }

    .discount-container {
      width: rem-calc(345);

      img {
        width: 100%;
      }
    }

    .note_container {
      bottom: rem-calc(10);
      padding: rem-calc(0 15);
      text-align: left;
    }
  }
}

@include breakpoint(xlarge up) {
  .spring_break_april {
    @at-root .no-webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/spring_break_april/bg.png');
    }

    @at-root .webp-lossy &.slider__slide--woken {
      background-image: url('pages/home_page/promotions_slider/spring_break_april/bg.webp');
    }

    .description {
      width: rem-calc(445);
    }

    .description1 {
      margin-bottom: rem-calc(55);
    }

    .description2 {
      margin-bottom: rem-calc(70);
    }

    .image-wrap {
      margin-bottom: rem-calc(15);
    }

    .image-container2 {
      width: rem-calc(354);

      img {
        width: 100%;
      }
    }

    .title-container {
      width: rem-calc(465);
    }

    .discount-container {
      width: rem-calc(422);
    }

    .text-with-code,
    .text-with-discount {
      font: {
        size: rem-calc(22);
      }
    }

    .text-with-code {
      left: rem-calc(8);

      &__code-container {
        width: rem-calc(110);
        margin-left: rem-calc(7);
      }
    }

    .text-with-discount {
      bottom: rem-calc(-60);
      left: rem-calc(250);
    }

    .note_container {
      padding: rem-calc(0 25);
    }
  }
}

@include breakpoint(1900px up) {
  .spring_break_april {
    .description {
      width: rem-calc(545);
    }

    .description1 {
      margin-bottom: rem-calc(21);
    }

    .description2 {
      margin-bottom: rem-calc(45);
    }

    .image-wrap {
      margin-bottom: rem-calc(10);
    }

    .title-container {
      width: rem-calc(565);
    }

    .discount-container {
      width: rem-calc(522);
    }

    .text-with-code,
    .text-with-discount {
      font: {
        size: rem-calc(24);
      }
    }

    .text-with-code {
      bottom: rem-calc(-23);
      left: rem-calc(15);
    }

    .text-with-discount {
      bottom: rem-calc(-35);
      left: rem-calc(300);
      width: rem-calc(410);

      span {
        display: inline;

        font: {
          weight: 800;
        }
      }
    }
  }
}
